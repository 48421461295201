
































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';

import BaseForm from '../components/BaseForm.vue';
import BaseTable from '../components/BaseTable.vue';
import BaseHeader from '../components/BaseHeader.vue';

import { Column } from '../shared/types/column.class';
import { CastType, Filter, Operator } from '../shared/types/filter.class';

import { Asset } from '../api/assets/asset.model';
import { Prop, Watch } from 'vue-property-decorator';
import { PaginateResult } from '../shared/types/paginate-result.class';
import { Project } from '@/api/projects/project.model';
import { Category } from '@/api/categories/category.model';
import {
  FormElement,
  FormElementOptions,
  FormElementType,
} from '@/shared/types/form-element.class';
import { User } from '@/api/users/user.class';
import { Role } from '@/api/auth/role.enum';

const assetsModule = namespace('assets');
const usersModule = namespace('users');
const projectsModule = namespace('projects');
const categoriesModule = namespace('categories');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseTable, BaseHeader, BaseForm } })
export default class AssetsTable extends Vue {
  @Prop({
    required: true,
  })
  filter!: Filter;

  @Prop({
    type: Array,
  })
  columns!: Column[];

  @Prop({
    default: true,
  })
  canUpdate!: boolean;

  @Prop({
    default: true,
  })
  canDelete!: boolean;

  @Prop({
    default: true,
  })
  canSearch!: boolean;

  @Prop()
  embedded!: boolean;

  showFilters = false;

  isLoading = false;

  isLoadingModal = false;

  localFilter = { project: [] };

  baseColumns: Column[] = [
    new Column('name'),
    new Column('type', 'Category', this.formatCategory.bind(this)),
    new Column('pricePerHour', 'Price per hour'),
    new Column('project', 'Project', this.formatProject.bind(this)),
    new Column('location.streetAndNumber', 'Address'),
    new Column('location.city', 'City'),
    new Column('location.zipCode', 'Zip code'),
    new Column('lastCommand', 'Current state'),
  ];

  filterElements: FormElement[][] = [];

  @usersModule.Getter('loggedInUser')
  user!: User;

  @assetsModule.Getter('all')
  allAssets!: Asset[];

  @assetsModule.Getter('current')
  asset!: Asset;

  @projectsModule.Getter('all')
  allProjects!: Project[];

  @categoriesModule.Getter('all')
  allCategories!: Category[];

  @assetsModule.Action('fetchById')
  fetchById!: (id: string) => Promise<Asset>;

  @assetsModule.Action('fetchAll')
  fetchAllAssets!: (filter?: Filter) => Promise<Asset[]>;

  @categoriesModule.Action('fetchAll')
  fetchAllCategories!: (filter?: Filter) => Promise<Category[]>;

  @assetsModule.Action('delete')
  deleteAsset!: (id: string) => Promise<Asset>;

  @Watch('filter', { deep: true })
  onFilterChanged() {
    this.doFetchAll();
  }

  formatCategory(column: Column, obj: Asset) {
    const category = this.allCategories.find(x => x._id === obj.type);
    return category?.title || 'Unknown';
  }

  formatProject(column: Column, obj: Asset) {
    const project = this.projects.find(x => x._id === obj.project);
    return project?.name || 'Unknown';
  }

  get showFilterButton() {
    if (
      (this.user.role === Role.SuperAdmin ||
        this.user.role === Role.CompanyAdmin) &&
      !this.embedded
    ) {
      return true;
    }
    return false;
  }

  get getColumns() {
    if (!this.columns || this.columns.length === 0) {
      return this.baseColumns;
    }
    return this.columns;
  }

  get getCanDelete() {
    return this.canDelete && this.$can('delete', 'Asset');
  }

  get getCanUpdate() {
    return this.canUpdate && this.$can('read', 'Asset');
  }

  @assetsModule.Getter('all')
  assets!: Asset[];

  @projectsModule.Getter('full')
  projects!: Project[];

  @assetsModule.Getter('pagination')
  pagination!: PaginateResult<Asset>;

  @assetsModule.Action('fetchAll')
  fetchAll!: (filter?: Filter) => Promise<Asset[]>;

  @projectsModule.Action('fetchFull')
  fetchAllProjects!: (filter?: Filter) => Promise<Project[]>;

  @assetsModule.Action('delete')
  delete!: (id?: string) => Promise<void>;

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAllProjects();
    await this.fetchAll(this.filter);
    this.isLoading = false;
  }

  doSearch(searchString: string) {
    this.filter.setSearch(searchString);
    this.doFetchAll();
  }

  doSort(field: string) {
    this.filter.setSort(field);
    this.doFetchAll();
  }

  onPageChanged(page: number) {
    this.filter.replaceFilter('page', page);
    this.doFetchAll();
  }

  onFilterClicked() {
    this.filterElements = [
      [
        new FormElement(
          'project',
          'Project',
          new FormElementOptions(this.projects, 'name', '_id'),
          '',
          FormElementType.SELECT,
        ),
      ],
    ];
    this.showFilters = true;
  }

  applyFilters() {
    this.filter.removeForKeyAndOperator('project', Operator.Equal);
    if (this.localFilter.project.length > 0) {
      this.filter.addFilter(
        'project',
        this.localFilter.project,
        Operator.Equal,
        CastType.ObjectId,
      );
    }
  }

  openDetail(asset: Asset) {
    this.$router.push({ name: 'AssetDetail', params: { id: asset._id } });
  }

  async doDelete(asset: Asset) {
    await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove the selected asset?`,
      {
        title: 'Confirm delete',
        okVariant: 'danger',
        centered: true,
      },
    );
    this.delete(asset._id);
  }

  async created() {
    this.doFetchAll();
  }
}
