import { BaseModel } from '@/shared/types/base.model';
import { ProfileInstruction } from './profile-instruction.model';

export class Profile extends BaseModel {
  constructor(
    public name: string = '',
    public instructions: ProfileInstruction[] = [],
    public type: string = 'CUSTOM',
  ) {
    super();
  }
}
