import { BaseModel } from '@/shared/types/base.model';
import { File } from '@/shared/types/file.model';

export class ProfileInstruction extends BaseModel {
  constructor(
    public type: string = '',
    public command: string = '',
    public image?: File,
    public label?: string,
  ) {
    super();
  }
}
