import { BaseModel } from '@/shared/types/base.model';
import { LifetimeState } from './lifetime-state.enum';
import { LockState } from './lock-state.enum';
import { KeywiFirmware } from './keywi-firmware.model';

export class KeyWi extends BaseModel {
  keyWiKeyPair?: string;

  constructor(
    public name?: string,
    public deviceNumber?: string,
    public lockState: LockState = LockState.Unknown,
    public lifetimeState: LifetimeState = LifetimeState.Unknown,
    public powerUpOn?: Date,
    public keyWiFirmware: KeywiFirmware = new KeywiFirmware(),
    public project?: string,
    public profile?: string,
    public advertisedDeviceName?: string,
  ) {
    super();
  }
}
