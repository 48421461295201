












































import Vue from 'vue';
import Component from 'vue-class-component';
import { KeyWi } from '../api/keywis/keywi.model';
import { namespace } from 'vuex-class';
import { Filter } from '../shared/types/filter.class';

import BaseHeader from '../components/BaseHeader.vue';
import KeywiForm from '../components/KeywiForm.vue';
import AssetsTable from '../components/AssetsTable.vue';
import LogsTable from '../components/LogsTable.vue';
import Toast from '../shared/types/toast.class';
import { ToastType } from '../shared/types/toast-type.enum';
import { SortOrder } from '../shared/types/sort-order.enum';

const keywisModule = namespace('keywis');
const appModule = namespace('app');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseHeader,
    KeywiForm,
    AssetsTable,
    LogsTable,
  },
})
export default class KeywiDetail extends Vue {
  assetsFilter = new Filter([]);

  detailTabs = ['Configuration', 'Assets'];
  detailTab = 'Configuration';
  currentLogTab = 'all';

  get isCreate() {
    return this.$route.name === 'NewKeywi';
  }

  get keywiName() {
    return this.isCreate ? 'New Keywi' : this.keywi?.name;
  }

  get isConfigurationActive() {
    return this.detailTab === this.detailTabs[0];
  }

  get isAssetsActive() {
    return this.detailTab === this.detailTabs[1];
  }

  get detailsBodyClass() {
    if (this.isAssetsActive) {
      return 'p-0';
    }
    return '';
  }

  get tabs() {
    if (!this.isCreate) {
      return this.detailTabs;
    }
    return [this.detailTabs[0], this.detailTabs[1]];
  }

  @keywisModule.Getter('current')
  keywi!: KeyWi;

  @keywisModule.Mutation('setCurrent')
  setCurrent!: (keywi: KeyWi) => void;

  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => void;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => void;

  @appModule.Mutation('addToast')
  addToast!: (toast: Toast) => void;

  @keywisModule.Action('fetchById')
  fetchById!: (id: string) => Promise<KeyWi>;

  @keywisModule.Action('update')
  update!: (keywi: KeyWi) => Promise<KeyWi>;

  @keywisModule.Action('delete')
  delete!: (id: string) => Promise<KeyWi>;

  @keywisModule.Action('create')
  create!: (keywi: KeyWi) => Promise<KeyWi>;

  onLogsTabChanged(tab: string) {
    this.currentLogTab = tab;
  }

  async doSave() {
    this.addLoader('app');
    if (this.isCreate) {
      const keywi = await this.create(this.keywi);
      this.$router.replace({
        name: 'KeywiDetail',
        params: { id: keywi._id },
      });
    } else {
      await this.update(this.keywi);
    }
    this.removeLoader('app');
  }

  async doDelete() {
    const result = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to delete ${this.keywi.name}?`,
      {
        title: 'Delete',
        okVariant: 'danger',
        centered: true,
      },
    );
    if (!result) return;

    this.addLoader('app');
    const toast = new Toast('Keywi deleted', ToastType.SUCCESS);
    try {
      await this.delete(this.keywi._id);
    } catch (e) {
      toast.message = e.message;
      toast.type = ToastType.ERROR;
    }
    this.addToast(toast);
    this.removeLoader('app');
    this.$router.go(-1);
  }

  async created() {
    this.addLoader('app');
    if (this.$route.params.id) {
      const keywi = this.$route.params.id;
      this.assetsFilter.addFilter('keyWi', keywi);
      await this.fetchById(keywi);
    } else {
      this.setCurrent(new KeyWi());
    }
    this.removeLoader('app');
  }
}
