





import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import BaseForm from '../components/BaseForm.vue';
import {
  FormElement,
  FormElementType,
  FormElementOptions,
} from '../shared/types/form-element.class';
import { KeyWi } from '../api/keywis/keywi.model';
import { Filter } from '../shared/types/filter.class';
import { Project } from '../api/projects/project.model';
import { Profile } from '../api/profiles/profile.model';

const projectsModule = namespace('projects');
const profilesModule = namespace('profiles');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseForm,
  },
})
export default class KeyWiForm extends Vue {
  @Prop()
  keywi!: KeyWi;

  formElements: FormElement[][] = [
    [
      new FormElement(
        'deviceNumber',
        'Device Number',
        new FormElementOptions(),
        'required',
      ),
      new FormElement('name', 'Name', new FormElementOptions(), 'required'),
    ],
    [
      new FormElement(
        'keyWiKeyPair.publicKey',
        'Public Key',
        new FormElementOptions(
          undefined,
          undefined,
          undefined,
          undefined,
          true,
        ),
      ),
    ],
    [
      new FormElement(
        'keyWiKeyPair.privateKey',
        'Private Key',
        new FormElementOptions(
          undefined,
          undefined,
          undefined,
          undefined,
          true,
        ),
      ),
    ],
  ];

  @projectsModule.Getter('all')
  projects!: Project[];

  @profilesModule.Getter('all')
  profiles!: Profile[];

  @projectsModule.Action('fetchAll')
  fetchAllProjects!: (filter?: Filter) => Promise<Project[]>;

  @profilesModule.Action('fetchAll')
  fetchAllProfiles!: (filter?: Filter) => Promise<Profile[]>;

  async created() {
    await this.fetchAllProfiles();
    this.formElements.splice(1, 0, [
      new FormElement(
        'profile',
        'Profile',
        new FormElementOptions(this.profiles, 'name', '_id'),
        'required',
        FormElementType.SELECT,
      ),
    ]);
    if (this.$can('read', 'Project')) {
      await this.fetchAllProjects();
      this.formElements.unshift([
        new FormElement(
          'project',
          'Project',
          new FormElementOptions(this.projects, 'name', '_id'),
          'required',
          FormElementType.SELECT,
        ),
      ]);
    }
  }
}
