












































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { BModal } from 'bootstrap-vue';

import BaseTable from '../components/BaseTable.vue';
import BaseHeader from '../components/BaseHeader.vue';
import ProfileForm from '../components/ProfileForm.vue';

import { Column } from '../shared/types/column.class';
import { Filter } from '../shared/types/filter.class';

import Toast from '../shared/types/toast.class';
import { Profile } from '../api/profiles/profile.model';
import { PaginateResult } from '@/shared/types/paginate-result.class';

const profilesModule = namespace('profiles');
const appModule = namespace('app');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseTable, BaseHeader, ProfileForm } })
export default class Profiles extends Vue {
  $refs!: Vue['$refs'] & {
    detailModal: BModal;
    addProfileModal: BModal;
  };

  isLoading = false;

  tabIndex = 0;
  columns: Column[] = [new Column('name', 'Name')];

  profileForDetail: Profile = new Profile();
  newProfile: Profile = new Profile();

  filter: Filter = new Filter();
  emailsForInvites: string[] = [];

  @profilesModule.Getter('all')
  profiles!: Profile[];

  @profilesModule.Getter('pagination')
  pagination!: PaginateResult<Profile>;

  @appModule.Mutation('addToast')
  addToast!: (toast: Toast) => void;

  @profilesModule.Action('fetchAll')
  fetchAll!: (filter?: Filter) => Promise<Profile[]>;

  @profilesModule.Action('delete')
  delete!: (id?: string) => Promise<void>;

  @profilesModule.Action('create')
  create!: (profile: Profile) => Promise<Profile>;

  @profilesModule.Action('update')
  update!: (profile: Profile) => Promise<Profile>;

  async doCreate(profile: Profile) {
    await this.create(profile);
    this.newProfile = new Profile();
    this.doFetchAll();
  }

  async doUpdate(profile: Profile) {
    await this.update(profile);
    this.profileForDetail = new Profile();
    this.doFetchAll();
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAll(this.filter);
    this.isLoading = false;
  }

  doSearch(searchString: string) {
    this.filter.setSearch(searchString);

    this.doFetchAll();
  }

  doSort(field: string) {
    this.filter.setSort(field);
    this.doFetchAll();
  }

  openCreateModal() {
    this.$refs.addProfileModal.show();
  }

  openDetail(profile: Profile) {
    this.profileForDetail = profile;
    this.$refs.detailModal.show();
  }

  async doDelete(profile: Profile) {
    const value = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove ${profile.name}?`,
      {
        title: 'Confirm delete',
        okVariant: 'danger',
        okTitle: 'Yes',
        centered: true,
      },
    );
    if (value) {
      await this.delete(profile._id);
      this.doFetchAll();
    }
  }

  onPageChanged(page: number) {
    this.filter.replaceFilter('page', page);
    this.doFetchAll();
  }

  clearEmails() {
    this.emailsForInvites = [];
  }

  created() {
    this.doFetchAll();
  }
}
