






import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import BaseForm from '../components/BaseForm.vue';
import BaseFormElement from '../components/BaseFormElement.vue';

import {
  FormElement,
  FormElementOptions,
  FormElementType,
} from '../shared/types/form-element.class';
import { Profile } from '../api/profiles/profile.model';
import { ProfileInstruction } from '../api/profiles/profile-instruction.model';

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseForm,
    BaseFormElement,
  },
})
export default class ProfileForm extends Vue {
  @Prop()
  profile!: Profile;

  profileTypes = [
    { text: 'Custom', value: 'CUSTOM' },
    { text: 'Salto', value: 'SALTO' },
    { text: 'KeyWi', value: 'KEYWI' },
  ];

  formElements: FormElement[][] = [
    [
      new FormElement('name', 'Name', new FormElementOptions(), 'required'),
      new FormElement(
        'type',
        'Type',
        new FormElementOptions(this.profileTypes, 'text', 'value'),
        'required',
        FormElementType.SELECT,
      ),
    ],
  ];

  types = [
    { value: 'OPEN' },
    { value: 'CLOSE' },
    { value: 'RELEASE_CORD' },
    { value: 'PORT4' },
  ];

  created() {
    for (let i = 0; i < this.profile.instructions.length; ++i) {
      this.addInstructionFormElement(i);
    }
  }

  addInstructionFormElement(i: number) {
    this.formElements.push([
      new FormElement(
        `instructions.${i}.type`,
        'Type',
        new FormElementOptions(this.types, 'value', 'value'),
        'required',
        FormElementType.SELECT,
      ),
      new FormElement(
        `instructions.${i}.command`,
        'Command',
        new FormElementOptions(),
        'required',
      ),
    ]);

    this.formElements.push([
      new FormElement(
        `instructions.${i}.requiresConfirm`,
        'Requires confirmation',
        new FormElementOptions(),
        'required',
        FormElementType.CHECKBOX,
      ),
      new FormElement(
        `instructions.${i}.confirmText`,
        'Confirmation text',
        new FormElementOptions(),
        'required',
      ),
    ]);

    this.formElements.push([
      new FormElement(
        `instructions.${i}.icon`,
        'Icon',
        new FormElementOptions(),
        'required',
        FormElementType.IMAGE,
      ),
      new FormElement(
        `instructions.${i}.label`,
        'Label',
        new FormElementOptions(),
        'required',
      ),
    ]);
  }

  addInstruction() {
    this.profile.instructions.push(new ProfileInstruction());
    const i = this.profile.instructions.length - 1;

    this.addInstructionFormElement(i);
  }
}
